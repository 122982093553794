import { DefaultUrlSerializer, UrlTree } from "@angular/router";

export class CustomUrlSerializerService extends DefaultUrlSerializer {
  constructor() {
    super();
  }

  public override parse(url: string): UrlTree {
    // Conditionally apply custom logic for a specific route
    if (url.startsWith('/new/ItemDetail/Board')) {
      // Custom encoding logic for this specific route
      const newUrl = this.myReplacer(decodeURI(url));   
      return super.parse(newUrl);      
    }
    if (url.includes('(') && url.includes(')'))
      {
        url=url.replace('(','')
        .replace(')','')  
      }
    // Fallback for all other routes
    return super.parse(url);
  }

  private myReplacer(str: string): string {
    //Encode
    str = str.replace('/new/ItemDetail/Board/', '') // Remove specific part
    .replace(/\//g, '%2F')  // Encode remaining /
    .replace(/\(/g, '%28')  // Encode (
    .replace(/\)/g, '%29'); // Encode )
  

// Prepend "/choose-your-boards/" to str
    str = `/choose-your-boards/${str}`;
  
    return str;
  }
}
