import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, max } from 'rxjs';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';
interface BoardInfo {
  description: string;
  suitable_users: string;
  material_finishes: string[];
  material_textures: string[];
  brands: string[];
  special_properties: string | null;
}

interface BoardDictionary {
  [key: string]: BoardInfo;
}
@Component({
  selector: 'app-choose-your-boards-details',
  templateUrl: './choose-your-boards-details.component.html',
  styleUrls: ['./choose-your-boards-details.component.scss']
})
export class ChooseYourBoardsDetailsComponent implements OnInit {
  materials=JSON.parse("{}")
  material!:any
  sasUrl =""
  boards= JSON.parse("{}")
  tags= JSON.parse("{}")
  m_code = ""
  genUrl :any
  notFound = false;

  a_data:BoardDictionary  = {
    "MDF board": {
      "description": "MDF is a medium density fibreboard used in many internal applications.",
      "suitable_users": "Suitable for Painting",
      "material_finishes": ["Colour", "Raw"],
      "material_textures": [],
      "brands": ["Finsa"],
      "special_properties": "Scratch Resistant Rating"
    },
    "Decorative board": {
      "description": "Our wide range of decorative boards contains both contemporary and traditional styles; with material finishes such as Matt, Gloss, Woodgrain, Leather, and Uni Colours.",
      "suitable_users": "Wardrobe Doors, Media Furniture e.g. TV Stand, Kitchen Replacement Doors, Bathroom Panels",
      "material_finishes": ["Melamine Faced Chipboard", "Wood"],
      "material_textures": ["Melamine Faced Chipboard", "Wood"],
      "brands": ["Xylo", "Cleaf"],
      "special_properties": "Scratch Resistant Rating"
    },
    "Plywood board": {
      "description": "Plywood is an extremely reliable and consistent board, renowned for being lightweight, having superior strength.",
      "suitable_users": "Suitable for Painting, Bathroom Panels",
      "material_finishes": ["Wood"],
      "material_textures": [],
      "brands": ["Latvian"],
      "special_properties": "Scratch Resistant Rating"
    },
    "Melamine board": {
      "description": "Melamine-faced boards have either a chipboard or MDF core with a melamine paper applied to each face.",
      "suitable_users": "Wardrobe Carcase, Kitchen Carcasing, Media Furniture e.g. TV Stand, Bathroom Panels",
      "material_finishes": ["Wood"],
      "material_textures": [],
      "brands": ["Xylo", "Cleaf"],
      "special_properties": "Scratch Resistant Rating"
    },
    "Veneered board": {
      "description": "Our veneer is made up from quality real wood veneers bonded to premium boards.",
      "suitable_users": "Suitable for Painting",
      "material_finishes": ["Wood", "Walnut Veneered"],
      "material_textures": [],
      "brands": ["Losen"],
      "special_properties": null
    },
    "Hollow Core board": {
      "description": "A Composite board with strong surface layers combined with light yet robust cardboard honeycomb core. In addition, its lightweight features and outstanding stability offer many design and construction options.",
      "suitable_users": "A raw hollowcore board has either an MDF or chipboard face that is suitable for onward processing. The MDF board is manufactured with 5mm MDF skins that can be primed ready to take paint, or the chipboard which has 8mm skins usually requires the pressing of veneer or laminate to create a ready to use panel.",
      "material_finishes": [],
      "material_textures": [],
      "brands": [],
      "special_properties": null
    }
  }
 bsModalRef!: BsModalRef;

  constructor(private apiService: EndpointApiService,private metadataService: MetadataService,private router: Router, private fb: FormBuilder, private route: ActivatedRoute, private el: ElementRef, private _http: HttpClient, private modalService: BsModalService) {
    
   }
  ngOnInit(): void {
    this.genUrl=this.apiService.getGenSasUrlAzure().subscribe((data:any)=>{
      this.sasUrl=data['signed_url'];
    })
    var dataFromSnapshot = this.route.snapshot.data['material_tags'];

    if (!dataFromSnapshot){
      this.notFound = true;
    }
    
    //this.sasUrl = d[4]["signed_url"]
    //DEcoding
    this.m_code = (this.route.snapshot.paramMap.get('photoId'))?.replace(/%2F/g, '/')
    .replace(/%28/g, '(')
    .replace(/%29/g, ')') as string; // Decoding

    this.materials = JSON.parse(JSON.stringify(dataFromSnapshot[0]))
    this.boards = JSON.parse(JSON.stringify(dataFromSnapshot[2]))
    this.tags = JSON.parse(JSON.stringify(dataFromSnapshot[3]))
    this.material = this.materials[this.m_code];
    this.metadataService.setCanonicalLink(this.m_code);
    this.metadataService.setTitle(this.m_code);
    this.metadataService.setDescription(this.getMaterialDescription());
  }
  getMaterialDescription(){
    return this.a_data[(this.material['type'])]['description']
  }
  getMaterialFinishes(){
    return this.a_data[(this.material['type'])]['material_finishes'].join(',')
  }
  getMaterialTextures(){
    return this.a_data[(this.material['type'])]['material_textures'].join(',')
  }
  getBrands(){
    
    let materialCode = this.tags.find((tag: any) => tag.code?.toLowerCase() ===(this.material.code).toLowerCase());

    if (!materialCode?.Tags?.BRAND) {
      return ''; // Return empty string or some default value if BRAND is missing
    }
    const brandName = Object.entries(materialCode.Tags.BRAND)
    .filter(([key, value]) => value === 1)  
    .map(([key, value]) => key)             
    .join(', ');                            
    return brandName;
    // return this.a_data[(this.material['type'])]['brands'].join(',')
  }
  getSheetSizes(){
   let boards = Object.keys(this.boards)
  .filter(board => this.material['stock_codes'].indexOf(board) !== -1);
    return boards.map(board => this.boards[board]['length'] + 'X' + this.boards[board]['width'] ).join(',')
  }
  getLeadTimes(){
    let boards = Object.keys(this.boards)
    .filter(board => this.material['stock_codes'].indexOf(board) !== -1);
      return boards.map(board => this.boards[board]['leadtime'] ).join(',')
  }
  getMaxPrice(){
    let boards = Object.keys(this.boards)
    .filter(board => this.material['stock_codes'].indexOf(board) !== -1);
    let maxp = 0
    boards.forEach(board => {
        if(this.boards[board]['sellingprice'] > maxp){
          maxp = this.boards[board]['sellingprice']
        }
      })
      return parseFloat(maxp.toString()).toFixed(2)
  }
  getSpecialD(){
    let tag =  this.tags.find((tag:any) => tag.code == this.m_code)
    return tag?.['Tags']?.['SPECIAL_OFFERS']?.['Special_Offers']== 1 ? "Yes": "No"
  }
  getHref() {
    let tag =  this.tags.find((tag:any) => tag.code == this.m_code)
    if (tag && tag['images'] && tag['images'].length > 0) {
      return this.sasUrl.replace('#fileName', tag['images'][0])
    } else {
      return "./alternate_board.jpeg"
    }

  }
  checkCondition() {
    let tag =  this.tags.find((tag:any) => tag.code == this.m_code)
    return tag['images']?.length > 0
  }

  openImageModal(template: TemplateRef<any>): void {
    this.bsModalRef = this.modalService.show(template, { class: 'img-modal'});
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }
  ngOnDestroy(){
    this.genUrl.unsubscribe()
  }
}
