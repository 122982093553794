import { isPlatformBrowser, KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';
import { environment } from 'environment.uat';

@Component({
  selector: 'app-choose-your-boards',
  templateUrl: './choose-your-boards.component.html',
  styleUrls: ['./choose-your-boards.component.scss']
})
export class ChooseYourBoardsComponent {
  tags: any;
  filtered_tags = []
  materials:any;
  form: FormGroup;
  sasUrl = ""
  searchKey = ""
  queryParams: any;
  materialsWithHideCms:any = [];
  materialArray:any=[]
  displayedTags: any[] = [];
  totalTags: any[] = [];
  itemsPerPage: number = 12;
  currentPage=1;
  fpSearchKey = "";
  genUrl :any;
  hostUrl  = environment.HOST_URL
  constructor( @Inject(PLATFORM_ID) private platformId: Object,private metadataService: MetadataService,private apiService: EndpointApiService,
    private router: Router, private fb: FormBuilder, private route: ActivatedRoute, private el: ElementRef, private http: HttpClient) {

    this.form = this.fb.group({})
 
  }  

  ngOnInit(): void {
    this.apiService.getGenSasUrlAzure().subscribe((data:any)=>{
      console.log("Getting fom NOPL...................")
      this.sasUrl=data['signed_url'];
    })
    var formHistory:any;
    this.metadataService.setCanonicalLink();
    this.metadataService.setTitle('Cutwrights - Choose your boards');
    this.metadataService.setDescription('Choose your board - Cutwrights');
    if (isPlatformBrowser(this.platformId)) {
      this.apiService.getGenSasUrlAzure().subscribe((data:any)=>{
        console.log("Getting fom PL...................")
        this.sasUrl=data['signed_url'];
      })
     formHistory = localStorage.getItem('formHistory');
    }
    setTimeout(() => {
    var data = this.route.snapshot.data['material_tags'];
    this.tags = data[3]
    this.materials = data[0];
    for (const key in this.materials) {
      if (this.materials.hasOwnProperty(key)) {
        const materialObject:any = this.materials[key];
        const hideInCmsValue = materialObject?.hideincms;
        if (materialObject?.hideincms === 1) {
          this.materialsWithHideCms.push(materialObject.code);
        }
        this.materialArray.push(materialObject.code);     
      }
    
    }  
    this.tags = this.tags.filter((tag: any) =>
    (!this.materialsWithHideCms.includes(tag['code'])) && this.materialArray.includes(tag['code'])
    );

    // this.sasUrl = data[4]["signed_url"]
    type FormGroups = { [group: string]: { [key: string]: string } };
    let formGroups: FormGroups = {};
    this.tags.forEach((tag: any) => {
      if (tag['Tags']) {
        Object.keys(tag['Tags']).forEach(group => {
          if (!formGroups[group]) {
            formGroups[group] = {};
          }

          Object.keys(tag['Tags'][group]).forEach(key => {
            if (!formGroups[group][key]) {
              formGroups[group][key] = "";
            }
          });
        });
      }

    });
    Object.keys(formGroups).forEach(key => {
      let n_group = this.fb.group({})
      Object.keys(formGroups[key]).forEach(val => {
        n_group.addControl(val, this.fb.control(0))
      })
      this.form.addControl(key, n_group)
    })
    this.form.valueChanges.subscribe(change => {
      this.onFilterChange(change);
    })
    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
      if (this.queryParams.searchKey != "") {
        this.searchKey = this.queryParams.searchKey
        this.searchByName()
      }
      else {
        this.filtered_tags = this.tags.slice(0, 10)
      }
    });

    this.totalTags = this.tags.slice();
    if (formHistory !== null) {
      this.form.setValue(JSON.parse(formHistory));
      
    }else{
      this.loadMoreItems(true);
    }
    // Initial Search for Fast Product Finder
    this.route.queryParams.subscribe(params => {
    this.fpSearchKey = params['searchKey']; 
    if (this.fpSearchKey!=""){
      const searchKeyWithoutSpaces = this.fpSearchKey.replace(/\s/g, '').toLowerCase();
      this.filtered_tags = this.tags.filter((tag: any) =>
        tag['code'].toLowerCase().includes(searchKeyWithoutSpaces)
      );
      if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('formHistory',JSON.stringify(this.form.value))
      }
      this.totalTags = this.filtered_tags.slice(); //Copy of filtered tags for slicing
      this.loadMoreItems(true); //Start Slicing
      this.scrollUp()
      }       
    });
  },2000);
  }
  
  filterFn = (data: any, filter: any) => {
    let r_val = true
    if (data) {
      Object.keys(data).forEach((category: any) => {
        var check = Object.keys(data[category]).some((f: string) => filter[category][f] === true)
        if (check) {
          let c_val = false;
          Object.keys(data[category]).forEach((fil: string) => {
            if (filter[category][fil] === true) {
              if (data[category][fil] == 1) {
                c_val ||= true
              }
            }
          })
          r_val &&= c_val;
        } else {
          r_val &&= true
        }
      })
    } else {
      return false
    }
    return r_val;
  }
  filterBoards(filter: any): any[] {
    const filteredTags = this.tags.filter((tag: any) =>
      this.filterFn(tag["Tags"], filter) && this.materialArray.includes(tag['code'])
    );
  
    // Update instance properties with filtered tags
    this.filtered_tags = filteredTags;
    this.totalTags = this.filtered_tags.slice(); // Copy of filtered tags for slicing
    this.loadMoreItems(true); // Start Slicing
  
    // Save form history to local storage
    if (isPlatformBrowser(this.platformId)) {
    localStorage.setItem('formHistory', JSON.stringify(this.form.value));
    }
    // Return the filtered tags array
    return filteredTags;
  }
  
  onFilterChange(change: any) {
    // Handle form submission here, e.g., send data to the server
    this.filterBoards(this.form.value)
  }
  
  getCount(categoryKey: string, subcategoryKey: string): number {
    // Get all filtered tags
    const filteredTags = this.filterBoards(this.form.value);
  
    // Count the number of matching items with the current sub-category
    let count = 0;
    for (const tag of filteredTags) {
      if (tag.Tags && tag.Tags[categoryKey] && tag.Tags[categoryKey][subcategoryKey] === 1) {
        count++;
      }
    }
  
    return count; 
  }
   isString(value: any): value is string {
    return typeof value === 'string';
  }
  
  // Your ensureString method using the type guard
   ensureString(value: any): string {
    return this.isString(value) ? value : '';
  }
  
  getKey(key: any) {
    return key.toString();
  }
  getTransformedKey(key: any): string {
    if (key.toLowerCase().startsWith('xylo_')) {
      return key.split('_')[1]; 
    }
    return key; 
  }
  
  getCategoryControls(category: any) {
    return category.value.controls
  }
  // Order by descending property key
  keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    const customOrder = [
      'Same_Day_(Subject_to_Capacity)',
      'Next_Day_(Subject_to_capacity)',
      '2_Days',
      '3_Days',
      '4_Days',
      '5_Days',
      '6_Days'
    ];
  
    const indexA = customOrder.indexOf(a.key as unknown as string);
    const indexB = customOrder.indexOf(b.key as unknown as string);
  
    if (a.value === 'Same_Day_(Subject_to_Capacity)' && b.value === 'Next_Day_(Subject_to_capacity)') {
      return -1; 
    } else if (a.value === 'Next_Day_(Subject_to_capacity)' && b.value === 'Same_Day_(Subject_to_Capacity)') {
      return 1; 
    }
  
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1; 
    }
  
    return 0;
  };
  
  getRootControls() {
    return this.form.controls
  }
  getHref(material: any) {
    if (material && material['images'] && material['images'].length > 0) {
      return this.sasUrl.replace('#fileName', material['images'][0])
    } else {
      return "./alternate_board.jpeg"
    }
  }


  searchByName() {
    const searchKeyWithoutSpaces = this.searchKey?.replace(/\s/g, '').toLowerCase();
    this.filtered_tags = this.tags.filter((tag: any) =>
      tag['code'].toLowerCase().includes(searchKeyWithoutSpaces)
    );
    if (isPlatformBrowser(this.platformId)) {
    localStorage.setItem('formHistory',JSON.stringify(this.form.value))
    }
    this.totalTags = this.filtered_tags.slice(); //Copy of filtered tags for slicing
    this.loadMoreItems(true); //Start Slicing
  }
  resetSearch() {
    this.searchKey = "";
    this.filterBoards(this.form.value);
  }
   checkCondition(material: any) {

    return material['images']?.length > 0
  }
  resetFilters() {    
    this.form.reset()
    this.resetSearch();
    this.currentPage=1;
  }
  navigateTo(code: string) {
    let clean_code: string;
    clean_code = code
      .replace(/\//g, '%2F')  // Replace / with %2F
      .replace(/\(/g, '%28')  // Encode (
      .replace(/\)/g, '%29'); // Encode )
  
    this.router.navigate([`./choose-your-boards/${clean_code}`]);
  }
  
  gotoViewAll(){
    this.router.navigate([`./choose-your-boards-viewall`]);
  }
  loadMoreItems(resetFlag :boolean ) {
      
      if(resetFlag){
        this.displayedTags=[]
      }
      // const startIndex = this.displayedTags.length;
      // const endIndex = startIndex + this.itemsPerPage;
    
    this.displayedTags.push(...this.totalTags);

  }
  scrollUp() {
    window.scrollTo({
      top: 230,
      behavior: 'smooth'
    });
  }
}
