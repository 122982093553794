import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Order, Quote } from 'src/app/models/cut-wrights.model';
import { PaymentResponseModel } from 'src/app/models/payment_response_model';
import { User } from 'src/app/models/user.model';
import { DataService } from 'src/app/service/data-service';

import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-invoice-receipt',
  templateUrl: './invoice-receipt.component.html',
  styleUrls: ['./invoice-receipt.component.scss']
})
export class InvoiceReceiptComponent implements AfterViewInit {
  quoteId!: string
  type!: string
  isLoading = false;
  quotation$;
  quote$;
  isQuote = false;
  payment$;
  user!: User;
  order!: Order;
  orderFetched = false
  quote!: Quote;
  orderId:string='';
  subscriptions: Subscription[] = [];
  impersonate:boolean=false;
  address:any=""
  city:any=""
  postcode:any=""

  paymentNotify$!: Observable<PaymentResponseModel>;
  constructor(private metadataService: MetadataService,private router: Router, private apiService: EndpointApiService, private route: ActivatedRoute, private dataService: DataService) {
    this.quoteId = this.route.snapshot.paramMap.get('quoteId') as string;
    this.type = this.route.snapshot.paramMap.get('type') as string;
    const token = localStorage.getItem('token');
    const userString = localStorage.getItem('user');
    if (token && this.type !=  "inva") {
      this.user = JSON.parse(userString as string)
    }
    if(localStorage.getItem('impersonate')){
      this.impersonate=true;
    }

    this.dataService.setHeaderFooterFlag(true);
    this.isQuote = this.type == "quote"
    this.quotation$ = this.apiService.getQuotationDetails(this.quoteId);
    this.quote$ = this.apiService.getQuote(this.quoteId);
    this.subscriptions.push(
    this.quote$.subscribe(data => {
      this.quote = data;
      
    if(this.user.roles.includes('admin')){
      this.apiService.getCustomers(this.quote.customerReference).subscribe(user => {
        if (user && user.length > 0) { 
          this.address=user[0].billingAddress?.address;
          this.city=user[0].billingAddress?.city;
          this.postcode=user[0].billingAddress?.postcode;
        }
      });
    }
    else{
      this.address=this.user.billingAddress?.address;
      this.city=this.user.billingAddress?.city;
      this.postcode=this.user.billingAddress?.postcode;
    }
     })
    );
    if (!this.isQuote) {
      this.payment$ = this.apiService.getPaymentDetails(this.quoteId);
      this.subscriptions.push(
      this.payment$.subscribe(data => {
        if(data && data.VPSTxID){
          this.paymentNotify$ = this.apiService.getPaymentNotifyDetails(data.VPSTxID);
        }
        
      })
      );
    }
    this.apiService.getOrder(this.quoteId).subscribe(data => {
      if (data) { 
        this.order = data;
        this.orderId = this.order.invoiceReference;
    
        if (this.type === "inva") {
          this.subscriptions.push(
          this.apiService.getCustomers(this.order.customerReference).subscribe(user => {
            if (user && user.length > 0) { 
              this.user = user[0];
              this.address=this.user.billingAddress?.address;
              this.city=this.user.billingAddress?.city;
              this.postcode=this.user.billingAddress?.postcode;
            }
          })
        );
          
        }
    
        this.orderFetched = true; 
      } else {
        console.error('No valid data returned from getOrder');
        this.orderFetched = false;
      }
    });

  }
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  
  updateTitleAndPrint() {
    document.title = 'Invoice_' + this.orderId;
    this.printContent();
  }
  printContent() {
        window.print();
  }
  truncateValue(value: number): string {
    return this.apiService.truncateDecimal(value);
  }
  truncateValueQuantity(value: number): string {
    return this.apiService.truncateDecimalQuantity(value);
  }
  ngAfterViewInit(): void {
    document.getElementById('header')!.style.display = 'none';
    document.getElementById('footer')!.style.display = 'none';
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  
}
