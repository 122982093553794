
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'environment.uat';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  private defaultTitle = 'MDF Cut to Size - Cutwrights Bespoke Board Cutting';
  private defaultDescription = 'Using the latest in Automated machinery, we deliver what we believe to be the “The Ultimate Cutting, Edging and Drilling Service”, across a huge range of boards and finishes.'; 

   constructor(@Inject(DOCUMENT) private document: Document,  @Inject(PLATFORM_ID) private platformId: Object,private titleService: Title, private metaService: Meta,private router: Router,) { }
  
  setTitle(title: string): void {
    const pageTitle = (title!='default') ? title : this.defaultTitle;
    this.titleService.setTitle(pageTitle);
  }

  setDescription(description: string): void {
    const pageDescription = (description!='default') ? description : this.defaultDescription;
    this.metaService.updateTag({ name: 'description', content: pageDescription });
  }
  // setCanonicalLink(): void {
  //         const canonicalUrl = `https://www.cwuat.com${this.router.url}`;
  //           this.metaService.addTag({ rel: 'canonical', href: canonicalUrl });
  // }
  setCanonicalLink(boardName?:any): void {
      // const canonicalUrl = `${environment.HOST_URL}${this.router.url}`;
      const hasQueryParams = this.router.url.includes('?');
      
      let canonicalUrl = hasQueryParams
        ?  `${environment.HOST_URL}`  // Canonical to homepage
        : `${environment.HOST_URL}${this.router.url}`; 

      if (boardName) {
          canonicalUrl = `${environment.HOST_URL}/new/ItemDetail/Board/${encodeURIComponent(boardName)}`;
      }
  
      // Remove any existing canonical link element
      const existingLink = this.document.querySelector('link[rel="canonical"]');
      if (existingLink) {
        this.document.head.removeChild(existingLink);
      }
      
      // Create a new link element
      const link = this.document.createElement('link');
      link.rel = 'canonical';
      link.href = canonicalUrl;
      // Append the new link element to the head
      this.document.head.appendChild(link);
    
  }
}
